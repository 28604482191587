@font-face {
  font-family: "Gilroy UltraLight";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-UltraLight.ttf");
  src: url("../../assets/Fonts/Gilroy-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy Thin";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-Thin.ttf");
  src: url("../../assets/Fonts/Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy Light";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-Light.ttf");
  src: url("../../assets/Fonts/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-Regular.ttf");
  src: url("../../assets/Fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy Medium";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-Medium.ttf");
  src: url("../../assets/Fonts/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy SemiBold";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-SemiBold.ttf");
  src: url("../../assets/Fonts/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy Bold";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-Bold.ttf");
  src: url("../../assets/Fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy ExtraBold";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-ExtraBold.ttf");
  src: url("../../assets/Fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy Black";
  font-style: normal;
  src: url("../../assets/Fonts/Gilroy-Black.ttf");
  src: url("../../assets/Fonts/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy Heavy";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/Fonts/Gilroy-Heavy.ttf");
  src: url("../../assets/Fonts/Gilroy-Heavy.ttf") format("truetype");
}