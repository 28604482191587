// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #ededf1;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #969a95;
$gray-b: #f5f7fd;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #e6e8ee;
$white: white;;
$extra-light:#3c3a3a;
$gray-light: #a2abac;
$gray-dark: #0d0d0d;
$light-blue: #f6faf9;
$tablebg: #f5fcfe;
$theme-light-sky-blue: #dde9fb;
$theme-bluish: #fbfcfd;
$theme-sky: #fdfdfd;
$extra-skyblue: #b9c3d7;
$extra-lighter: #f3f3f3;
$extra-text-light:#a9a9a9;
$theme-light-gray:#4d5551;
$theme-greyish: #737b81;

// Color Shades (Theme Based - Xclusive)
$theme-primary: #a1cc37;
$theme-dark-purple: #384167;
$theme-secondary: #171a16;
$theme-success: #8ee2c3;
$theme-hover-success:#2f8f4e;
$theme-text: #373737;
$theme-success-light: #8bddcd;
$theme-success-dark: #168d75;
$theme-info: #7581b4;
$theme-info-bg: #222121;
$theme-warning: #ff8238;
$theme-warning-bg: #fffaf7;
$theme-success-bg: #f2fff4;
$theme-danger: #f96464;
$theme-pink-dark: #ff0845;
$theme-danger-dark: #ff3265;
$theme-danger-bg: #fff5f5;
$theme-yellow: #e3c040;
$theme-offwhite: #f3f5fb;
$theme-cyan: #49558a;
$theme-cyan-bg: #b7c0a0;
$theme-pink: #c83375;
$theme-pink-bg: #fafafa;
$theme-gray: rgb(232, 240, 254);
$theme-link: #004b8f;
$theme-green-line: #153534;
$theme-blue-line: #b5d2f5;
$theme-blue-bg: #F3FCFA;
$theme-shadow: #d6d6d6;
$theme-light-green: #E8F1FDCC;
$theme-dark-green:  #797878;
$theme-light-blue: #03A9F4;
$theme-modal-shadow: rgb(106, 136, 183);
$theme-blue: #0c2426;
$theme-red: #d15050;
$theme-dark-red:rgb(231, 67, 86);
$theme-green: #1dbc9c;
$theme-purple: #53506f;
$theme-light-black: rgba(0, 0, 0, 0.7);
$theme-skiny: #eaeaea;
$theme-box-shadow: rgba(42, 42, 40, 0.12);
$theme-dark-black: #232d0b;
$theme-dark-brown: #2c3e50;
$theme-primary-shadow: rgba(42, 42, 40, 0.05);
$theme-brown: #7f7f7f;
$theme-weatish: #979797;
$theme-redish: #fd4265;
$theme-yellowish: #ffc800;
$theme-lime: #57c024;
$theme-whitish: #f0f8f5;
$theme-blackish: #252424;
$theme-seagreen: #cdf8e8;
$theme-light-brown: #858a92;
$theme-light-purple: #cad2d3;
$theme-lemon: #9fcb35;
$theme-weetish: #e2e7e7;
$theme-orange: #f7b500;
$theme-dark-redish: #fc4c4c;
$theme-light-greenish: #529638;
$theme-cream: #636d7e;
$theme-dark-blue: #007aff;
$theme-lemon-green: #a2cc39;
$theme-pinkish: #fc2924;
$theme-blakish-black: #2a2a28;
$theme-silver: #848484;
$theme-dark-blackish: #0b0b0b;
$theme-lime: #7d8a8f;
$theme-dark: #262626;
$theme-brownish: #484848;
$theme-dark-bluish: #3e3e3e;
$theme-light-brownish: #5a5b5b;
$theme-cream-color: #e3eec9;
$theme-dark-greenish: #4c7e64;
$theme-off-whitish: #f9fafe;
$theme-grayish: #f4f4f4;
$theme-six: #666666;
$theme-bright-green: #add649;
$theme-silver-color: #dcdcdc;
$theme-four: #4f4f4f;
$theme-evergreen: #ebedf4;
$theme-bright-yellow: #90bb27;
$theme-creamy: #cbcbcb;
$theme-fish-color: #969696;
$theme-bg: #f9f9f9;
$theme-brownie: #8b8c8a;
$theme-white: #dedede;
$theme-blood-red: #ff433c;
