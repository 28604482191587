@import '../styles/variables/fonts';
@import '../styles/variables/colors';
@import '../styles/variables/mixins';

//body
body {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: $theme-bg;

  a {
    text-decoration: none;
  }

  //d-flex
  .d-flex {
    display: flex;
  }

  //justify-space
  .justify-space {
    justify-content: space-between;
  }

  //d-none
  .d-none {
    display: none;
  }

  select {
    &:focus {
      outline: 0;
    }
  }

  //visibility-hidden
  .visibility-hidden {
    visibility: hidden;
  }

  //align-center
  .align-center {
    align-items: center;
  }

  //placeholder
  input::placeholder {
    font-family: 'Gilroy Medium';
    color: $theme-dark-green;
    font-size: 1rem;
    opacity: 0.8;
    font-weight: 300;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  //modal-backdrop.show
  .modal-backdrop.show {
    opacity: 1;
    background-color: rgba($black, 0.68);
    backdrop-filter: blur(1rem);
  }

  //icon-img-download
  .icon-img-download {
    width: 20px;
    height: 22.3px;
  }

  //icon.download
  .icon.download {
    border-radius: 7.5px;
    box-shadow: 0 5px 13px 0 rgba($black, 0.11);
    border: 2px solid $theme-primary;
    padding: 0.8rem 0.7rem;

    @include mobile {
      padding: 0.55rem 0.45rem;
    }

    &:nth-child(1) {
      margin-right: 1rem;
    }
  }

  //orange
  .orange {
    border: 2px solid $theme-orange;
    color: $theme-orange;
  }

  //red
  .red {
    border: 2px solid $theme-dark-redish;
    color: $theme-dark-redish;
  }

  //blue
  .blue {
    border: 2px solid $theme-dark-blue;
    color: $theme-dark-blue;
  }

  //green
  .green {
    border: 2px solid $theme-light-greenish;
    color: $theme-light-greenish;
  }

  //lemon-green
  .lemon-green {
    border: 2px solid $theme-lemon-green;
    color: $theme-lemon-green;
  }

  //outlines
  .outlines {
    font-family: 'Gilroy Medium';
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.11px;
    width: 92.5px;
    height: 27.5px;
    border-radius: 6.3px;
    background-color: $white;
    text-transform: capitalize;
    margin-bottom: 0;

    &:focus {
      outline: 0;
    }
  }

  //button-primary-disabled
  .button-primary-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $white;
    font-size: 0.95rem;
    height: 36px;
    border-radius: 0.5rem;
    width: 38%;
    border: 0;
    margin-right: 1rem;
    font-weight: 600;
    background-color: rgba($theme-primary, 0.5);
    cursor: not-allowed;

    &:focus,
    &:hover {
      background-color: rgba($theme-primary, 0.5);
      color: $white;
      outline: 0;
    }
  }

  .error {
    font-family: 'Gilroy Medium';
    font-size: 0.9rem;
    font-weight: 500;
    color: $theme-pink-dark;
    margin: 0 !important;
    padding-top: 10px;
  }
}

//button[disabled]
button[disabled],
button[disabled]:hover {
  background-color: rgba($theme-primary, 0.5) !important;
  color: $white !important;
}

//backdrops
.backdrops {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(6px);
  background-color: rgba($black, 0.6);
  cursor: pointer;
}

//.search-input .filter-commodities
.commodity-catelogue {
  .search-input .filter-commodities.search-container {
    width: 100%;
  }

  .search-input .filter-commodities {
    width: 43%;

    @include mobile {
      width: 100%;
    }

    @include tablet {
      width: 100%;
    }

    @include ipad-pro {
      width: 69%;
    }
  }

  .other-commodities {
    display: none;
  }
}

// main-header-section
.main-header-section {
  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.modal-dialog {
  @include mobile {
    margin: 0;
  }
}

.layout-grid.white {
  display: none;
}
