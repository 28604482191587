// ----------------------------------
// Media Query Mixin
// ----------------------------------

$font-primary: "Gilroy", sans-serif;

$iphone5-width: 350px;
$iphone6-width: 375px;
$mobile-width: 414px;
$tablet-width: 768px;
$tablet-p-width: 991px;
$desktop-width: 1024px;
$desktop-width-md: 1280px;
$desktop-width-lg: 1440px;
$full-hd-display: 1920px;

// iPhone5 Mixin
@mixin iphone5 {
  @media (max-width: #{$iphone5-width}) {
    @content;
  }
}

// medium mobiles Mixin
@mixin sm-mobile {
  @media (min-width: #{$iphone5-width + 1}) and (max-width: #{$iphone6-width}) {
    @content;
  }
}

// iPhone6 Mixin
@mixin iphone6 {
  @media (min-width: #{$iphone6-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

// max-mobile Mixin
@mixin max-mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

// Mobile Mixin
@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

// Mobile Mixin (Landscape Orientation)
@mixin mobile-o-l {
  @media (max-width: #{$tablet-width - 1px}) and (orientation:landscape) {
    @content;
  }
}

// Mobile-Tablet(P) Mixin
@mixin mobile-tablet-p {
  @media (max-width: #{$tablet-p-width}) {
    @content;
  }
}

// Tablet (L/P) Mixin
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

// Tablet ipad pro
@mixin ipad-pro {
  @media (min-width: #{$tablet-p-width + 1px}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

// Tablet (P) Mixin
@mixin tablet-p {
  @media (min-width: #{$tablet-width}) and (max-width: #{$tablet-p-width}) {
    @content;
  }
}

// Tablet (L) Mixin
@mixin tablet-l {
  @media (min-width: #{$tablet-p-width + 1px}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

// Tablet & Desktop Mixin
@mixin tablet-desktop {
  @media (min-width: #{$tablet-p-width + 1px}) {
    @content;
  }
}

// Tablet (L/P) & Desktop Mixin
@mixin tablet-desktop-full {
  @media (min-width: #{$tablet-width - 1px}) {
    @content;
  }
}

// Desktop Mixin
@mixin desktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

// Medium Desktops
@mixin desktop-width-md {
  @media (min-width: #{$desktop-width + 1px}) and (max-width: #{$desktop-width-md}) {
    @content;
  }
}

// Large Desktops
@mixin desktop-lg {
  @media (min-width: #{$desktop-width-lg}) {
    @content;
  }
}

// full hd
@mixin desktop-hd {
  @media (min-width: #{$full-hd-display}) {
    @content;
  }
}

// Chrome Browsers Only
@mixin chrome {
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    @content;
  }
}
