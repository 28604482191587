@import '../../../styles/variables/mixins';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';

//insight-listings-table
.insight-listings-table {

    @include mobile {
        border-bottom: 0!important;
    }

    //insight-list-name
    .insight-list-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        border-bottom: 1.3px solid rgba($theme-weatish, 0.2);
        padding: 1.7rem 1rem;
        margin: 0 1rem;
        @include mobile {
            margin: 0 0.7rem;
            padding: 2rem 0 0.5rem;
        }

        @include tablet {
            padding: 2.5rem 0 0.5rem;
        }

        &:hover {
            background-color: $gray-b;
        }

        .insight-flag {
            @include mobile {
                display: none;
            }
        }
    }

    //listings
    .listings {
        width: 14.24%;
    }

    //insight-image
    .insight-image {
        position: relative;
        top: -0.2rem;
        width: 20%;
    }

    //insight-name
    .insight-name {
        width: 50%;
    }

    //insight-download
    .insight-download {
        width:15%;
        display: flex;
        justify-content: flex-end;
        @include mobile{
            width: 30%;
        }
        //outline
        .outline {
            margin: 0;
            //button-outline
            .button-outline {
                height: 32px;
                font-size: 14px;
                color: $theme-secondary;
                padding-top: 4px;
                @include mobile {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
            }
        }
    }

    //insight-img
    .insight-img {
        height: 12%;
    }
}
